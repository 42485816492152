<template>
  <div class="Information">
    <Part1></Part1>
    <Part2></Part2>
  </div>
</template>

<script>
import Part1 from '@/components/information/Part1.vue'
import Part2 from '@/components/information/Part2.vue'

export default {
  name: 'Information',
  components: {
    Part1,
    Part2
  },
  mounted() {
    window.scroll(0, 0)
  }
}
</script>
