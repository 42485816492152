<template>
  <div class="Part2">
    <div class="w">
      <!-- <div class="titleBlack">
        <div>服务一体化粮食管理解决方案专家</div>
        <div class="tipBlack">
          全渠道全场景农业解决方案，助力农业产业打造智慧农业生态
        </div>
      </div>-->
      <div class="content">
        <div
          class="item"
          @click="
            toLink('http://www.ckzx.agri.cn/ctpxw/202404/t20240416_440069.htm')
          "
        >
          <img src="@/assets/image/information/news.png" alt />
          <div class="right">
            <div class="title">
              农业部、省农业厅领导莅临荃银高科、一村科技指导
            </div>
            <div class="tip">
              2024年4月初，农业部、省农业厅领导莅临荃银高科、一村科技指导 “
              种粮一体化”平台合作模式——探讨“荃银高科+一村科技+产业合
              作伙伴+金融合作伙伴”合作经营模式的形成 …
            </div>
            <div class="tip">日期：2024-04-09</div>
          </div>
        </div>
        <div
          class="item"
          @click="toLink('https://nyncj.hefei.gov.cn/zwxx/gsgg/15069197.html')"
        >
          <img src="@/assets/image/information/11.png" alt />
          <div class="right">
            <div class="title">
              一村科技承建“合肥虾通”龙虾产业互联网平台，
              徽茶产业链数字化改造（徽茶网）通过验收
            </div>
            <div class="tip">
              2023年12月初一村科技承建“合肥虾通”龙虾产业互联网平台，徽茶产业链数字化改造（徽茶网）通过验收
              …
            </div>
            <div class="tip">日期：2023-12-10</div>
          </div>
        </div>

        <div
          class="item"
          @click="toLink('https://drc.hefei.gov.cn/zxdt/tzgg/15030347.html')"
        >
          <img src="@/assets/image/information/10.png" alt />
          <div class="right">
            <div class="title">
              一村科技荣获政府奖励，助力“种粮一体化”平 台发展
            </div>
            <div class="tip">
              一 村 科 技 “种 粮 一 体 化 ” 平 台 荣获“2022年合肥市推动经济
              高质量发展若干政策及支持线上经济发展若干政策第二批拟扶持项
              目”，政府奖励200万元… …
            </div>
            <div class="tip">日期：2023-8-21</div>
          </div>
        </div>
        <div
          class="item"
          @click="toLink('https://mp.weixin.qq.com/s/V8wtiRyJaIh5esaFPs3Htg')"
        >
          <img src="@/assets/image/information/640.png" alt />
          <div class="right">
            <div class="title">
              喜报！一村科技荣获“合肥市2023年优秀应用案例”奖项
            </div>
            <div class="tip">
              8月31日，由合肥市经济和信息化局、合肥经济技术开发区管理委员会指导，合肥经开区经济发展局、合肥市工业互联网协会、合肥市经合智能制造工业互联网创新中心主办的...
            </div>
            <div class="tip">日期: 2023-9-4</div>
          </div>
        </div>
        <div
          class="item"
          @click="toLink('http://nync.ah.gov.cn/snzx/zwxxi/56862301.html')"
        >
          <img src="@/assets/image/information/9.png" alt />
          <div class="right">
            <div class="title">皖美 光大种业贷产品上线仪式在合肥举行</div>
            <div class="tip">
              2023年8月初 种粮一体化“农业产业互联网平台”皖美
              光大种业贷“产品上线仪式在合肥举行”...
            </div>
            <div class="tip">日期: 2023-8-3</div>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/image/information/8.png" alt />
          <div class="right">
            <div class="title">
              农发行赋能“种粮一体化”全产业链交流座谈会顺利开展
            </div>
            <div class="tip">
              2023年3月3日，中国农发行总行及安徽分行调研“种粮一体化创新研究中心...
            </div>
            <div class="tip">日期: 2023-3-3</div>
          </div>
        </div>
        <div
          class="item"
          @click="toLink('http://www.winallseed.com/article/11251/52.html')"
        >
          <img src="@/assets/image/information/7.png" alt />
          <div class="right">
            <div class="title">《“种粮一体化”共建项目》正式发布</div>
            <div class="tip">
              2022年8月底合肥一村信息科技有限公司与荃银高科共建种粮一体化...
            </div>
            <div class="tip">日期: 2022-8-26</div>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/image/information/1.png" alt />
          <div class="right">
            <div class="title">一村科技与银联及南商行业务上线</div>
            <div class="tip">
              2021年11月合肥一村信息科技有限公司与银联及南商行业务上线...
            </div>
            <div class="tip">日期: 2021-11-30</div>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/image/information/2.png" alt />
          <div class="right">
            <div class="title">2021年下半年一村科技获得高新技术企业证书</div>
            <div class="tip">
              多年来, 一村科技产品的优化、服务的提升、细节的完善, 每一步...
            </div>
            <div class="tip">日期: 2021-11-18</div>
          </div>
        </div>
        <div class="item" @click="toLink('http://www.ahyicun.com/zlyiti/')">
          <img src="@/assets/image/information/3.png" alt />
          <div class="right">
            <div class="title">一村科技与荃银高科共建种粮一体化平台</div>
            <div class="tip">
              2021年9月合肥一村信息科技有限公司与荃银高科农业产业共建种...
            </div>
            <div class="tip">日期: 2021-9-25</div>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/image/information/4.png" alt />
          <div class="right">
            <div class="title">一村科技与国家信息中心达成战略合作</div>
            <div class="tip">
              2020年10月合肥一村信息科技有限公司与国家信息中心达成战略...
            </div>
            <div class="tip">日期: 2020-10-28</div>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/image/information/5.png" alt />
          <div class="right">
            <div class="title">一村科技与平安银行达成战略合作协议</div>
            <div class="tip">
              2020年5月合肥一村信息科技有限公司与平安银行达成战略合作协...
            </div>
            <div class="tip">日期: 2020-5-28</div>
          </div>
        </div>
        <div class="item">
          <img src="@/assets/image/information/6.png" alt />
          <div class="right">
            <div class="title">合肥一村信息科技有限公司注册成立</div>
            <div class="tip">
              2019年8月合肥一村信息科技有限公司注册成立, 经营范围: 计算...
            </div>
            <div class="tip">日期: 2019-8-22</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    to(path, query) {
      this.$router.push({
        path: "/Jump",
        query: { path, query },
      });
    },
    toLink(link) {
      window.open(link);
    },
  },
};
</script>
<style lang="less" scoped>
.Part2 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #f8f8f9;
  .titleBlack {
    margin-bottom: 160px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      color: #33333388;
      /* line-height: 40px; */
      margin-top: 30px;
    }
  }
  img {
    width: 100%;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .item {
      width: 100%;
      background-color: #fff;
      padding: 40px;
      margin: 10px 0;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: 1s all;
      img {
        width: 400px;
        margin-right: 50px;
      }
      .right {
        flex: 1;
        text-align-last: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 20px;
        > div {
          margin: 20px 0;
        }
        .title {
          font-weight: 700;
        }
        .tip {
          font-size: 16px;
          color: #000;
        }
      }
    }
    .item:hover {
      cursor: pointer;
      transform: translate(0, -2px);
      box-shadow: 3px 3px 10px #ddd;
    }
  }
}
</style>
